/*
* @author 友人a丶
* @date 2022-07-11
*
* 系统静态配置
* */


export default {
    loaded: false, //用户信息是否已经初始化
    filterRoutes: false //路由是否已经加载
};